
import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import { getUserInfo, updateUser, withdrawUser } from '@/api/user';
import { getEncData, getInfo } from '@/api/nice';
import { ElForm } from 'element-ui/types/form';
import { parseConcatNumber } from '@/filters';
import { getRedirectUrl, removeRedirectUrl } from '@/utils/cookies';
import moment from 'moment';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getEncData();
    this.getUserInfo();
    if (this.$route.query.EncodeData) {
      this.setUserInfo();
    }
  }

  created() {
    /* eslint-disable */
    this.lastLoginType = localStorage.getItem('lastLoginType');
    const { code, state, id_token } = (this.$route.query as any);
    if (code && state) {
      if (state === 'google') {
        UserModule.GoogleLogin(this.$route.query.code as string).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'google');
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.loading = false;
          this.$router.replace({ query: {} });
        });
      } else {
        UserModule.NaverLogin(this.$route.query.code as string).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'naver');
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.$router.replace({ query: {} });
          this.loading = false;
        });
      }
    } else if (code) {
      UserModule.KakaoLogin(this.$route.query.code as string).then(async (res) => {
        if (res === 'login') {
          localStorage.setItem('lastLoginType', 'kakao');
          await removeRedirectUrl();
        }
      }).catch(() => {
        this.$router.replace({ query: {} });
        this.loading = false;
      });
    } else {
      console.log(this.$route);
      if (this.$route.hash.includes('id_token')) {
        const idToken = this.$route.hash.substring(this.$route.hash.indexOf('id_token=') + 9);
        UserModule.AppleLogin(idToken).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'apple');
            const redirectUrl = getRedirectUrl();
            if (redirectUrl) this.$router.push({ path: redirectUrl });
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.$router.replace({ query: {} });
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }
    /* eslint-enable */
  }

  private handleNaverLogin() {
    (window as any).location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.VUE_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOCATION}/login&state=123qwe123qawfweer4gb56u45`;
  }

  private handleKakaoLogin() {
    const clientId = process.env.VUE_APP_KAKAO_CLIENT_ID;
    const redirectUri = process.env.VUE_APP_KAKAO_REDIRECT_URL;
    (window as any).Kakao.init(clientId);
    (window as any).Kakao.Auth.authorize({
      redirectUri,
    });
  }

  private async handleGoogleLogin() {
    const client = (window as any).google.accounts.oauth2.initCodeClient({
      client_id: '564186867142-3jeppc2n5gibqa5c882vqdr63ls1cf93.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/userinfo.profile',
      ux_mode: 'redirect',
      redirect_uri: `${process.env.VUE_APP_LOCATION}/login`,
      state: 'google',
    });
    client.requestCode();
  }

  private handleAppleLogin() {
    window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${process.env.VUE_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOCATION}/login&response_type=code id_token&response_mode=fragment`;
  }

  get actualName() {
    return UserModule.actualName;
  }

  private encData = '';

  private loading = true;

  private isNicePass = false; // 본인인증 여부

  private infoForm = {
    actualName: '',
    concatNumber: '',
    birth: '',
    email: '',
  }

  private lastLoginType: string | null = '';

  private getEncData() {
    getEncData({ redirectUrl: '/mypage/infoIndex' }).then((res) => {
      this.encData = res.data;
    });
  }

  private getUserInfo() {
    this.loading = true;
    getUserInfo().then((res) => {
      this.infoForm = res.data;
      this.loading = false;
    });
  }

  private handleNiceCheckPlus() {
    (this.$refs.niceForm as HTMLFormElement).action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
    (this.$refs.niceForm as HTMLFormElement).submit();
  }

  private handleUpdate() {
    (this.$refs.infoForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.loading = true;
        updateUser(this.infoForm).then(() => {
          this.$message.success('정보수정을 완료했습니다.');
          this.isNicePass = false;
          this.getUserInfo();
        }).catch(() => {
          this.$message.error('정보수정을 실패했습니다.');
          this.isNicePass = false;
          this.getUserInfo();
        });
      }
    });
  }

  private setUserInfo() {
    getInfo(String(this.$route.query.EncodeData)).then((res) => {
      this.infoForm.actualName = res.data.name;
      this.infoForm.concatNumber = parseConcatNumber(res.data.phone);
      const birth = moment(res.data.birth, 'YYYYMMDD').format('YYYY-MM-DD');
      this.infoForm.birth = birth;
      this.isNicePass = true;
    });
  }

  private goBack() {
    this.$router.go(-1);
  }

  private handleWithdraw() {
    if (UserModule.roles.indexOf('ROLE_ADMIN') !== -1 || UserModule.roles.indexOf('ROLE_BUSINESS') !== -1) {
      this.$message.error('관리자 계정은 해당 기능을 사용 할 수 없습니다. 고객센터에 문의하세요');
    } else {
      this.$prompt('정말 회원을 탈퇴하시겠습니까? \n 회원탈퇴를 원하시면 \'남해군 원스톱 관광플랫폼 회원탈퇴\'를 입력하세요', '회원탈퇴', {
        confirmButtonText: '회원탈퇴',
        cancelButtonText: '취소',
      }).then((value: any) => {
        if (value.value === '남해군 원스톱 관광플랫폼 회원탈퇴') {
          this.$confirm(' 해당 계정과 연계되어 있는 카카오, 네이버, 구글, 애플 계정이 전부 삭제되오니 신중히 요청하시길 바랍니다. 정말 회원을 탈퇴하시겠습니까?', '회원탈퇴', {
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            type: 'warning',
          }).then(() => {
            withdrawUser().then(() => {
              this.$message({
                type: 'info',
                message: '성공적으로 회원탈퇴 되셨습니다. 30일 경과시 사용하셨던 모든 데이터는 말소됩니다.',
              });
              UserModule.LogOut();
              this.$router.push({ name: 'Login' });
            }).catch((error) => {
              console.log(error.response.data);
              this.$message.error(error.response.data.message || '회원탈퇴에 실패하였습니다.');
            });
          });
        } else {
          this.$message.info('문구가 올바르지 않습니다.');
        }
      });
    }
  }
}
